import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/home/">Strona główna</a>
                </li>
                <li>
                  <a className="sel" href="/produkty/">
                    Produkty
                  </a>
                </li>
                <li>
                  <a href="/poradnik/">Poradnik</a>
                </li>
                <li>
                  <a href="/dobierz-lek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/pytania-i-odpowiedzi/">Pytania i odpowiedzi</a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Strona główna</a>
            <a href="/produkty/" className="sel">
              {" "}
              &gt; Produkty
            </a>
          </div>
          <div id="page_content">
            <div className="col2">
              <div className="drugs">
                <div className="box">
                  <div className="photo">
                    <img src="/web/kcfinder/uploads/files/produkty/Sudafed_DEX_kartonik%2Bbutelka%20.png" />
                  </div>
                  <div className="info">
                    <p className="name">
                      SUDAFED<sup>®</sup> XYLOSPRAY DEX
                    </p>
                    <div className="desc">
                      Odblokowuje nos, przyspiesza gojenie ran i chroni
                      podrażnioną błonę śluzową nosa
                    </div>
                    <a href="/produkty/sudafed_xylospray_dex/" className="more">
                      WIĘCEJ
                    </a>
                  </div>
                </div>
                <div className="box">
                  <div className="photo">
                    <img src="/web/kcfinder/uploads/files/produkty/Sudafed_DEX_Kids_kartonik%2Bbutelka%20.png" />
                  </div>
                  <div className="info">
                    <p className="name">
                      SUDAFED<sup>®</sup> XYLOSPRAY DEX DLA DZIECI
                    </p>
                    <div className="desc">
                      Szybko i delikatnie odblokowuje i pomaga w gojeniu ran w
                      małych noskach
                    </div>
                    <a
                      href="/produkty/sudafed_xylospray_dex_kids/"
                      className="more"
                    >
                      WIĘCEJ
                    </a>
                  </div>
                </div>
                <div className="box">
                  <div className="photo">
                    <img src="/web/kcfinder/uploads/files/produkty/xylosprayha.png" />
                  </div>
                  <div className="info">
                    <p className="name">
                      SUDAFED<sup>®</sup> <br />
                      XYLOSPRAY HA
                    </p>
                    <div className="desc">
                      Jako jedyny
                      <span className="note_nr" /> zawiera aż 3 substancje,
                      które nawilżają: kwas hialuronowy (w postaci soli
                      sodowej), sorbitol, glicerol.
                    </div>
                    <a href="/produkty/sudafed_xylospray_ha/" className="more">
                      WIĘCEJ
                    </a>
                  </div>
                </div>
                <div className="box">
                  <div className="photo">
                    <img src="/web/kcfinder/uploads/files/produkty/packshot_bez_tla.png" />
                  </div>
                  <div className="info">
                    <p className="name">
                      SUDAFED<sup>®</sup> <br />
                      XYLOSPRAY HA DLA DZIECI
                    </p>
                    <div className="desc">
                      Szybko i delikatnie odblokowuje i nawilża małe noski.
                    </div>
                    <a
                      href="/produkty/sudafed_xylospray_ha_dla_dzieci/"
                      className="more"
                    >
                      WIĘCEJ
                    </a>
                  </div>
                </div>
                <div className="box">
                  <div className="photo">
                    <img src="/web/kcfinder/uploads/files/produkty/xylospray.png" />
                  </div>
                  <div className="info">
                    <p className="name">
                      SUDAFED<sup>®</sup> <br />
                      XYLOSPRAY
                    </p>
                    <div className="desc">
                      Szybko odblokowuje nos i zatoki oraz ułatwia usunięcie
                      zalegającej wydzieliny.
                    </div>
                    <a href="/produkty/sudafed_xylospray/" className="more">
                      WIĘCEJ
                    </a>
                  </div>
                </div>
                <div className="box">
                  <div className="photo">
                    <img src="/web/kcfinder/uploads/files/produkty/dla_dzieci.png" />
                  </div>
                  <div className="info">
                    <p className="name">
                      SUDAFED<sup>®</sup> <br />
                      XYLOSPRAY DLA DZIECI
                    </p>
                    <div className="desc">
                      Szybko i delikatnie odblokowuje nos i zatoki. Umożliwia
                      spokojny sen bez kataru aż do 10 godzin
                    </div>
                    <a
                      href="/produkty/sudafed_xylospray_dla_dzieci/"
                      className="more"
                    >
                      WIĘCEJ
                    </a>
                  </div>
                </div>
                <div className="box">
                  <div className="photo">
                    <img src="/web/kcfinder/uploads/files/produkty/actifed.png" />
                  </div>
                  <div className="info">
                    <p className="name">
                      <span
                        style={{
                          color: "#cb0073",
                        }}
                      >
                        ACTIFED<sup>®</sup> SYROP
                        <br /> NA KATAR I KASZEL
                      </span>
                    </p>
                    <div className="desc">
                      Odblokowuje nos, zmniejsza ilość wydzieliny oraz łagodzi
                      suchy kaszel.
                    </div>
                    <a
                      href="/produkty/actifed_syrop_na_katar_i_kaszel/"
                      className="more"
                    >
                      WIĘCEJ
                    </a>
                  </div>
                </div>
                <div className="box">
                  <div className="photo">
                    <img src="/web/kcfinder/uploads/files/produkty/tabletki_02.png" />
                  </div>
                  <div className="info">
                    <p className="name">
                      SUDAFED<sup>®</sup> <br />
                      TABLETKI
                    </p>
                    <div className="desc">
                      Odblokowuje nos oraz eliminuje uczucie ucisku w głowie i
                      zatokach
                      <span className="note_nr" />
                    </div>
                    <a href="/produkty/sudafed_tabletki/" className="more">
                      WIĘCEJ
                    </a>
                  </div>
                </div>
              </div>
              <div className="note">
                <p>
                  1 Wśród produktów zawierających Xylometazolinę/Oxymetazolinę w
                  postaci aerozolu do nosa, dopuszczonych do obrotu w Polsce
                  według Urzędowego Wykazu Produktów Leczniczych Dopuszczonych
                  do Obrotu na terytorium Rzeczypospolitej Polskiej, według
                  stanu na dzień 1 Lutego 2023 r.
                </p>
                <p>
                  2 Dotyczy uczucia ucisku w zatokach spowodowanego nagromadzoną
                  wydzieliną i obrzękiem błony śluzowej nosa
                </p>
              </div>
            </div>
            <div className="col1">
              <div className="related">
                <p className="name">Dowiedz się więcej</p>
                <div className="line">
                  <a href="/poradnik/katar/jak_prawidlowo_oczyszczac_nos_podczas_kataru">
                    <div className="c1">
                      <span
                        className="photo"
                        style={{
                          backgroundImage:
                            'url("/web/kcfinder/uploads/files/artykuly/shutterstock_300834140.jpg")',
                        }}
                      />
                    </div>
                    <div className="c2">
                      Jak prawidłowo oczyszczać nos podczas kataru?{" "}
                    </div>
                  </a>
                </div>
                <div className="line">
                  <a href="/poradnik/katar/poznaj_rodzaje_kataru">
                    <div className="c1">
                      <span
                        className="photo"
                        style={{
                          backgroundImage:
                            'url("/web/kcfinder/uploads/files/artykuly/shutterstock_388715146.jpg")',
                        }}
                      />
                    </div>
                    <div className="c2">Poznaj rodzaje kataru </div>
                  </a>
                </div>
              </div>
              <a href="/dobierz-lek/" className="link_box link">
                <img src="/web/kcfinder/uploads/files/box/zrobtest.png" />
              </a>
              <a href="/reklama-telewizyjna/" className="link_box video">
                <img src="/web/kcfinder/uploads/files/box/SudafedHAthumbnail.png" />
              </a>
              <a href="/produkty/" className="quiz_btn" />
            </div>
            <div className="clear" />
          </div>
        </div>
        <footer>
          <div className="page_width">
            <div className="drug_alert"></div>
            <p
              style={{
                "font-size": "10px",
                "-webkit-text-align": "right",
                "text-align": "right",
                padding: "0 0 10px",
              }}
            >
              MAT/6769/03/2019
            </p>
          </div>
          <div className="footer_content">
            <div className="page_width">
              <ul id="footer_menu">
                <li>
                  <a href="/polityka-prywatnosci/">Polityka prywatności</a>
                </li>
                <li>
                  <a href="/polityka-cookies/">Polityka cookies</a>
                </li>
                <li>
                  <a href="/nota-prawna/">Nota prawna</a>
                </li>
                <li>
                  <a href="/mapa-strony/">Mapa strony</a>
                </li>
                <li>
                  <a href="/slowniczek/">Słowniczek</a>
                </li>
                <li>
                  <a
                    id="ot-sdk-btn"
                    className="ot-sdk-show-settings"
                    rel="nofollow"
                  >
                    {" "}
                    Ustawienia plików Cookie{" "}
                  </a>
                </li>
              </ul>
              <div className="rel footer_info">
                <br />
                Podmiot odpowiedzialny: McNeil Products Limited <br />© JNTL Consumer Health (Poland) sp. z o.o. 2023<br />
                JNTL Consumer Health (Poland) sp. z o.o. w ramach swojej działalności gospodarczej prowadzi hurtowy obrót produktami leczniczymi. Jeżeli jesteś przedsiębiorcą prowadzącym aptekę ogólnodostępną i jesteś zainteresowany nabyciem produktów leczniczych oferowanych przez nas, uprzejma prośba o kontakt z działem Customer Service email: <a href="mailto:apteki@kenvue.com">apteki@kenvue.com</a>. Minimalna wartość zamówienia 500 PLN brutto. Zamówienia realizowane będą wyłącznie po otrzymaniu pełnej przedpłaty.
              </div>
            </div>
          </div>
          <div className="footer_sile">
            <div className="page_width">
              <strong>Sudafed XyloSpray</strong>
              <br />
              Jeden ml aerozolu do nosa, roztworu zawiera 1 mg ksylometazoliny
              chlorowodorku. Wskazania: Zmniejszenie obrzęku błony śluzowej nosa
              w ostrym, w naczynioruchowym oraz w alergicznym zapaleniu błony
              śluzowej nosa. Ułatwienie odpływu wydzieliny w zapaleniu zatok
              przynosowych oraz w zapaleniu trąbki słuchowej ucha środkowego
              połączonego z przeziębieniem. Do stosowania u dorosłych i dzieci w
              wieku powyżej 6 lat. Przeciwwskazania: Nadwrażliwość na substancję
              czynną lub na którąkolwiek substancję pomocniczą. Nie stosować u
              pacjentów po usunięciu przysadki i po innych zabiegach
              chirurgicznych przebiegających z odsłonięciem opony twardej.
              Podmiot odpowiedzialny: McNeil Healthcare (Ireland) Limited.
              <br />
              <br />
              <strong>Sudafed XyloSpray HA</strong>
              <br />
              Jeden ml aerozolu do nosa, roztworu zawiera 1 mg ksylometazoliny
              chlorowodorku. Wskazania: Zmniejszenie obrzęku błony śluzowej nosa
              w ostrym, w naczynioruchowym oraz w alergicznym zapaleniu błony
              śluzowej nosa. Ułatwienie odpływu wydzieliny w zapaleniu zatok
              przynosowych oraz w zapaleniu trąbki słuchowej ucha środkowego
              połączonego z przeziębieniem. Do stosowania u dorosłych i dzieci w
              wieku powyżej 6 lat. Przeciwwskazania: Nadwrażliwość na substancję
              czynną lub na którąkolwiek substancję pomocniczą. Nie stosować u
              pacjentów po usunięciu przysadki i po innych zabiegach
              chirurgicznych przebiegających z odsłonięciem opony twardej.
              Podmiot odpowiedzialny: McNeil Healthcare (Ireland) Limited.
              <br />
              <br />
              <strong>Sudafed Xylospray DEX</strong>
              <br />
              Każdy ml aerozolu do nosa, roztworu zawiera 1 mg ksylometazoliny
              chlorowodorku i 50 mg deksopantenolu. Wskazania: Zmniejszenie
              obrzęku błony śluzowej w zapaleniu błony śluzowej nosa oraz w
              wspomagającym leczeniu uszkodzeń błony śluzowej nosa. Łagodzenie
              objawów naczynioruchowego zapalenia błony śluzowej nosa. Leczenie
              niedrożności przewodów nosowych po przebytej operacji nosa.
              Wskazany do stosowania u dorosłych i dzieci w wieku powyżej 6 lat.
              Przeciwwskazania: Nadwrażliwość na substancje czynne lub na
              którąkolwiek substancję pomocniczą. Suche zapalenie błony śluzowej
              nosa. Nie stosować po przezklinowym usunięciu przysadki mózgowej
              lub innych zabiegach chirurgicznych, przebiegających z
              odsłonięciem opony twardej. Przeciwwskazany u dzieci w wieku
              poniżej 6 lat. Podmiot odpowiedzialny: McNeil Healthcare (Ireland)
              Limited.
              <br />
              <br />
              <strong>Sudafed XyloSpray dla dzieci</strong>
              <br />
              Jeden ml aerozolu do nosa, roztworu zawiera 0,5 mg ksylometazoliny
              chlorowodorku. Wskazania: Krótkotrwałe leczenie objawowe
              przekrwienia błony śluzowej nosa w przebiegu zapalenia błony
              śluzowej nosa lub zapalenia zatok. Przeciwwskazania: Nadwrażliwość
              na substancję czynną lub na którąkolwiek substancję pomocniczą.
              Nie stosować u: pacjentów z podwyższonym ciśnieniem
              wewnątrzgałkowym, zwłaszcza u pacjentów z jaskrą z wąskim kątem
              przesączania; pacjentów z suchym zapaleniem błony śluzowej nosa;
              dzieci w wieku poniżej 2 lat; pacjentów po usunięciu przysadki i
              po innych zabiegach chirurgicznych wykonywanych drogą przeznosową
              lub przez jamę ustną, przebiegających z odsłonięciem opony
              twardej; pacjentów, którzy przyjmują lub przyjmowali przez
              ostatnie dwa tygodnie leki z grupy inhibitorów monoaminooksydazy
              oraz tych, którzy przyjmują inne leki mogące zwiększać ciśnienie
              krwi; pacjentów z zanikowym lub naczynioruchowym zapaleniem błony
              śluzowej nosa. Podmiot odpowiedzialny: McNeil Healthcare (Ireland)
              Limited.
              <br />
              <br />
              <strong>Sudafed XyloSpray HA dla dzieci</strong>
              <br />
              Jeden ml aerozolu do nosa, roztworu zawiera 0,5 mg ksylometazoliny
              chlorowodorku. Wskazania: Krótkotrwałe leczenie objawowe
              przekrwienia błony śluzowej nosa w przebiegu zapalenia błony
              śluzowej nosa lub zapalenia zatok. Przeciwwskazania: Nadwrażliwość
              na substancję czynną lub na którąkolwiek substancję pomocniczą.
              Nie stosować u: pacjentów z podwyższonym ciśnieniem
              wewnątrzgałkowym, zwłaszcza u pacjentów z jaskrą z wąskim kątem
              przesączania; pacjentów z suchym zapaleniem błony śluzowej nosa;
              dzieci w wieku poniżej 2 lat; pacjentów po usunięciu przysadki i
              po innych zabiegach chirurgicznych wykonywanych drogą przeznosową
              lub przez jamę ustną, przebiegających z odsłonięciem opony
              twardej; pacjentów, którzy przyjmują lub przyjmowali przez
              ostatnie dwa tygodnie leki z grupy inhibitorów monoaminooksydazy
              oraz tych, którzy przyjmują inne leki mogące zwiększać ciśnienie
              krwi; pacjentów z zanikowym lub naczynioruchowym zapaleniem błony
              śluzowej nosa. Podmiot odpowiedzialny: McNeil Healthcare (Ireland)
              Limited.
              <br />
              <br />
              <strong>Sudafed Xylospray DEX dla dzieci</strong>
              <br />
              Każdy ml aerozolu do nosa, roztworu zawiera 0,5 mg ksylometazoliny
              chlorowodorku i 50 mg deksopantenolu. Wskazania: Zmniejszenie
              obrzęku błony śluzowej w zapaleniu błony śluzowej nosa oraz w
              wspomagającym leczeniu uszkodzeń błony śluzowej nosa. Łagodzenie
              objawów naczynioruchowego zapalenia błony śluzowej nosa. Leczenie
              niedrożności przewodów nosowych po przebytej operacji nosa.
              Wskazany do stosowania u dzieci w wieku od 2 do 6 lat.
              Przeciwwskazania: Nadwrażliwość na substancje czynne lub na
              którąkolwiek substancję pomocniczą. Suche zapalenie błony śluzowej
              nosa. Nie stosować po przezklinowym usunięciu przysadki mózgowej
              lub innych zabiegach chirurgicznych, przebiegających z
              odsłonięciem opony twardej. Przeciwwskazany u dzieci w wieku
              poniżej 2 lat. Podmiot odpowiedzialny: McNeil Healthcare (Ireland)
              Limited.
              <br />
              <br />
              <strong>Actifed</strong>
              <br />5 ml syropu zawiera 1,25 mg triprolidyny chlorowodorku, 30
              mg pseudoefedryny chlorowodorku oraz 10 mg dekstrometorfanu
              bromowodorku. Wskazania: Łagodzenie objawów towarzyszących stanom
              zapalnym górnych dróg oddechowych, w których korzystne jest
              podanie leku zmniejszającego przekrwienie błony śluzowej nosa,
              antagonisty receptora histaminowego H<sub>1</sub> oraz leku
              przeciwkaszlowego. Do stosowania u dorosłych i dzieci w wieku od 7
              lat. Przeciwwskazania: Nadwrażliwość na substancje czynne lub na
              którąkolwiek substancję pomocniczą. Ciężkie nadciśnienie tętnicze
              lub ciężka choroba wieńcowa. Nie stosować u pacjentów, którzy
              przyjmują lub przyjmowali przez ostatnie dwa tygodnie leki z grupy
              inhibitorów monoaminooksydazy. Jednoczesne stosowanie
              pseudoefedryny i tego rodzaju leków może niekiedy prowadzić do
              wzrostu ciśnienia tętniczego krwi lub przełomu nadciśnieniowego.
              Istnieje ryzyko wystąpienia syndromu serotoninowego podczas
              stosowania razem z dekstrometorfanem. Nie stosować u pacjentów
              przyjmujących furazolidon. Ciężka niewydolność wątroby. Podmiot
              odpowiedzialny: McNeil Healthcare (Ireland) Limited.
              <br />
              <br />
              <strong>Sudafed</strong>
              <br />
              Jedna tabletka powlekana zawiera 60 mg pseudoefedryny
              chlorowodorku. Wskazania: Objawowe leczenie zapalenia błony
              śluzowej nosa i zatok przynosowych (katar, zatkany nos) w
              przebiegu: przeziębienia, grypy, alergicznego zapalenia błony
              śluzowej nosa. Lek przeznaczony jest dla dorosłych i dzieci w
              wieku powyżej 12 lat. Przeciwwskazania: Nadwrażliwość na
              substancję czynną lub na którąkolwiek substancję pomocniczą.
              Ciężkie nadciśnienie tętnicze lub ciężka choroba wieńcowa. Nie
              stosować u pacjentów, którzy przyjmują lub przyjmowali przez
              ostatnie dwa tygodnie leki z grupy inhibitorów monoaminooksydazy.
              Jednoczesne stosowanie produktu Sudafed i tego rodzaju leków może
              niekiedy prowadzić do wzrostu ciśnienia tętniczego krwi lub
              przełomu nadciśnieniowego. Nie stosować u pacjentów przyjmujących
              furazolidon. Podmiot odpowiedzialny: McNeil Healthcare (Ireland)
              Limited.
              <br />
              <br />
              Przed użyciem zapoznaj się z ulotką, która zawiera wskazania,
              przeciwwskazania, dane dotyczące działań niepożądanych i
              dawkowanie oraz informacje dotyczące stosowania produktu
              leczniczego, bądź skonsultuj się z lekarzem lub farmaceutą, gdyż
              każdy lek niewłaściwie stosowany zagraża Twojemu życiu lub
              zdrowiu.
            </div>
          </div>
        </footer>{" "}
      </div>
    );
  }
}

export default Page;
